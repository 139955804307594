<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-15 13:35:34
 * @LastEditTime: 2021-05-16 14:51:56
 * @FilePath: /shop_frontend/src/views/goodsCreate/editProduct.vue
-->

<template>
  <a-spin :spinning="spinning" tip="视频上传中..." size="large">
    <div class="product">
      <p class="product__title">基本信息</p>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" label="商品名称" prop="name">
          <a-input
            class="w220"
            v-model="form.name"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item label="商品描述">
          <a-textarea
            placeholder="商品描述"
            :rows="4"
            v-model="form.description"
          />
        </a-form-model-item>
        <a-form-model-item label="商品货号">
          <a-input class="w220" v-model="form.number" />
        </a-form-model-item>
        <a-form-model-item label="商品主图">
          <div class="imgBox">
            <div v-for="el in fileList" :key="el.id" class="img__show">
              <img :src="el.image" alt="" />
              <span class="delBtn" @click.self.stop="delImg(el)">删除</span>
              <span class="enlargeBtn" @click.self.stop="handleEnlargeImage(el.image)">放大</span>
            </div>
            <span v-if="fileList.length > 0">
              <a-button type="danger" @click="delAlImage">全部删除</a-button>
            </span>
          </div>
          <div class="photoBtn" @click="openPhoto">
            <a-icon type="plus" />
            <div>上传图片</div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="商品视频">
          <div class="video__box">
            <div
                  style="
                    display: inline-block;
                    width: 100px;
                    height: 20px;
                    line-height: 20px;
                    color: red;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.3);
                    font-size: 10px;
                    cursor: pointer;
                  "
                  @click.self="deleteVideo"
                  v-if="form.cover_url"
                >
                  删除视频
                </div>
          <a-upload
            name="file"
            action="/api/goods_videos/"
            :withCredentials="true"
            :headers="headers"
            @change="uploadChange"
            :multiple="false"
            :showUploadList="false"
          >
            <div>
                

                <img
                  :src="form.cover_url"
                  style="display: inline-block; width: 100px; height: 100px"
                  alt=""
                  v-if="form.cover_url"
                />
              <a-button
                style="margin-left: 10px"
                type="primary"
                v-if="form.cover_url"
                @click="openCover(form.file)"
                >播放</a-button
              >
              <a-button type="primary" v-else
                ><a-icon type="plus" />上传视频</a-button
              >
            </div>
          </a-upload>
          </div>
          <span style="color: red; margin-left: 10px"
            >建议上传尺寸800px*800px</span
          >
        </a-form-model-item>

        <!-- <a-form-model-item :wrapperCol="{span: 10, offset: 3}">
           <a-button type="link">展开更多配置<a-icon type="down" /></a-button>
      </a-form-model-item> -->
        <a-form-model-item label="单位">
          <a-select style="width: 220px" v-model="form.unit">
            <a-select-option
              :value="item.id"
              v-for="item in unitsList"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="商家分类">
          <a-select style="width: 220px" v-model="form.category">
            <a-select-option
              :value="item.id"
              v-for="item in categoriesList"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="初始销量">
          <a-input class="w220" v-model="form.initial_sales" />
          <span style="margin-left: 5px; opacity: 0.5"
            >设置后，您的用户看到的销量=初始销量+下单量，初始销量不计入统计</span
          >
        </a-form-model-item>
        <a-form-model-item label="商品规格">
          <div class="box">
            <div>
              <ul v-for="(item, index) in specArray" :key="index">
                <li>
                  <span>规格{{ index + 1 }}：</span>
                  <span class="input__box"
                    ><a-input style="width: 100px" v-model="item.spec"></a-input
                    ><a-icon
                      type="close-circle"
                      class="input__box_close"
                      @click="deleteSpec(index, specArray)"
                  /></span>
                </li>
                <li>
                  <span>{{ item.spec }}值：</span>
                  <span
                    class="input__box"
                    v-for="(el, i) in item.specList"
                    :key="el"
                    style="margin-right: 10px"
                    ><a-input style="width: 100px" v-model="el.name"></a-input
                    ><a-icon
                      type="close-circle"
                      class="input__box_close"
                      @click="deleteSpec(i, item.specList)"
                  /></span>
                  <span
                    ><a-button size="small" @click="addSpec(item)"
                      >添加{{ item.spec }}</a-button
                    ></span
                  >
                </li>
              </ul>
            </div>
            <div class="box__btn">
              <a-button size="small" @click="addName">添加规格</a-button>
            </div>
          </div>
        </a-form-model-item>

        <a-form-model-item label="规格价格" :wrapperCol="{ span: 20 }">
          <a-table
            :columns="columnsShow"
            :data-source="dataSource"
            :pagination="false"
            bordered
            :scroll="{ x: 1200 }"
          >
            <template slot="price" slot-scope="text, record">
              <a-input
                style="width: 100px"
                v-model="record.price"
                @change="changePrice"
              ></a-input>
            </template>
            <template slot="cost_price" slot-scope="text, record">
              <a-input
                style="width: 100px"
                v-model="record.cost_price"
              ></a-input>
            </template>
            <template slot="market_price" slot-scope="text, record">
              <a-input
                style="width: 100px"
                v-model="record.market_price"
              ></a-input>
            </template>
            <template slot="quantity" slot-scope="text, record">
              <a-input style="width: 100px" v-model="record.quantity"></a-input>
            </template>
            <template slot="weight" slot-scope="text, record">
              <a-input style="width: 100px" v-model="record.weight"></a-input>
            </template>
            <template slot="picture" slot-scope="text, record">
              <img
                v-if="record.image"
                :src="record.image"
                style="
                  width: 100px;
                  height: 100px;
                  display: inline-block;
                  margin-bottom: 10px;
                "
                alt=""
                @click="openSpec(record)"
              />
              <div class="photoBtn" @click="openSpec(record)" v-else>
                <a-icon type="plus" />
                <div>上传图片</div>
              </div>
            </template>
            <template
              :slot="'card' + item.id"
              slot-scope="text, record"
              v-for="item in cardList"
            >
              <a-input
                style="width: 100px"
                v-model="record[`card${item.id}`]"
                :key="item.id"
              ></a-input>
            </template>
          </a-table>
          <!-- <ul class="batch__setting">
            <li>批量设置</li>
            <li @click="batchSetting('price')">专柜价格</li>
            <li @click="batchSetting('cost_price')">成本价格</li>
      <li @click="batchSetting('market_price')">市场价格</li>
            <li @click="batchSetting('quantity')">库存</li>
            <li @click="batchSetting('weight')">重量</li>
          </ul> -->
        </a-form-model-item>

        <p class="product__title">配送信息</p>

        <a-form-model-item label="运费模板">
          <a-select style="width: 220px" v-model="form.freight_template">
            <a-select-option
              :value="item.id"
              v-for="item in freightList"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发货地">
          <a-input
            class="w220"
            placeholder="最多15个字"
            v-model="form.shop_address"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
      <a-modal
        title="添加规格"
        :visible="visible"
        @ok="handleOk"
        @cancel="visible = false"
      >
        <div>
          <a-form-model
            :model="info"
            layout="horizontal"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-form-model-item label="名称">
              <a-input
                class="w220"
                placeholder="最多15个字"
                v-model="info.name"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="批量设置">
              <a-checkbox @change="onChange" v-model="checkedAll">
                全选(点文字可选中)</a-checkbox
              >
              <a-checkbox-group
                :options="plainOptions"
                v-model="info.setList"
                class="custom__checkbox_group"
              />
              <a-checkbox-group
                v-model="cardChoose"
                class="custom__checkbox_group"
              >
                <a-checkbox
                  :value="item"
                  v-for="item in cardList"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </a-form-model-item>
            <!-- <a-form-model-item label="会员价">
            <a-checkbox-group v-model="cardChoose">
              <a-checkbox :value="item" v-for="item in cardList" :key="item.id">
                {{ item.name }}
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item> -->
          </a-form-model>
        </div>
      </a-modal>
      <a-modal
        title="新增"
        :visible="specValVisible"
        @ok="handleOkSpec"
        @cancel="specValVisible = false"
      >
        <div>
          <a-form-model
            :model="editForm"
            layout="horizontal"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }"
          >
            <a-form-model-item label="名称">
              <a-input
                class="w220"
                placeholder="最多15个字"
                v-model="editForm.name"
              ></a-input>
            </a-form-model-item>
            <template v-for="item in currentEditSpec.setList">
              <a-form-model-item :label="plainOptionsShow(item)" :key="item">
                <a-input
                  v-model="editForm[item]"
                  v-if="item !== 'picture'"
                ></a-input>
                <div v-else>
                  <img
                    v-if="editForm.image"
                    :src="editForm.image"
                    style="
                      width: 100px;
                      height: 100px;
                      display: inline-block;
                      margin-bottom: 10px;
                    "
                    alt=""
                    @click="openSet(editForm)"
                  />
                  <div class="photoBtn" @click="openSet(editForm)" v-else>
                    <a-icon type="plus" />
                    <div>上传图片</div>
                  </div>
                </div>
              </a-form-model-item>
            </template>
            <template v-for="item in currentEditSpec.cardList">
              <a-form-model-item :label="item.name" :key="item.id">
                <a-input v-model="editForm[`card${item.id}`]"></a-input>
              </a-form-model-item>
            </template>
          </a-form-model>
        </div>
      </a-modal>
      <photoList
        :visible="photoVisible"
        @ok="photoOk"
        @cancel="photoCancel"
      ></photoList>
      <photoList
        :visible="specVisible"
        @ok="specOk"
        @cancel="specCancel"
        :multiple="false"
      ></photoList>
      <photoList
        :visible="setVisible"
        @ok="setOk"
        @cancel="setVisible = false"
        :multiple="false"
      ></photoList>
    </div>

    <a-modal v-model="imageVisible" :closable="false" :footer="null">
      <div>
        <img :src="enlargeImage" alt="" style="width:100%;" />
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      checkedAll: false,
      spinning: false,
      currentEditSpec: {},
      plainOptions: [
        { label: "专柜价格", value: "price" },
        { label: "成本价格", value: "cost_price" },
        { label: "库存", value: "quantity" },
        { label: "重量", value: "weight" },
        { label: "规格图片", value: "picture" },
      ],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      visible: false,
      photoVisible: false,
      specVisible: false,
      specValVisible: false,
      setVisible: false,
      currentSpec: {},
      editForm: {},
      info: {
        name: "",
        setList: [],
      },
      form: {
        name: "",
        number: "",
        pictures: [],
        description: "",
        unit: "",
        category: "",
        freight_template: "",
        shop_address: "",
        initial_sales: 0,
        spec_items: [],
      },
      specArray: [],
      cardChoose: [],
      columns: [
        {
          title: "专柜价格(元)",
          dataIndex: "price",
          key: "price",
          scopedSlots: { customRender: "price" },
          width: 150,
        },
        {
          title: "成本价格(元)",
          dataIndex: "cost_price",
          key: "cost_price",
          scopedSlots: { customRender: "cost_price" },
          width: 150,
        },
        // {
        //   title: "市场价格(元)",
        //   dataIndex: "market_price",
        //   key: "market_price",
        //   scopedSlots: { customRender: "market_price" },
        // },
        {
          title: "库存",
          dataIndex: "quantity",
          key: "quantity",
          scopedSlots: { customRender: "quantity" },
          width: 150,
        },
        {
          title: "重量(kg)",
          dataIndex: "weight",
          key: "weight",
          scopedSlots: { customRender: "weight" },
          width: 150,
        },
        {
          title: "规格图片",
          dataIndex: "picture",
          key: "picture",
          scopedSlots: { customRender: "picture" },
          width: 150,
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
        ],
      },
      unitsList: [],
      categoriesList: [],
      freightList: [],
      fileList: [],
      cardList: [],
      cardDataSource: [],
      dataSource: [],
      fileLists: [],
      detailInfo: {},

      imageVisible: false,
      enlargeImage: undefined,
    };
  },
  components: {
    photoList: () => import("@/components/photoList"),
  },
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
    columnsShow() {
      let list = [];
      this.specArray.forEach((el) => {
        list.push(el.spec);
      });
      let str = list.join(" ");

      let index = this.columns.findIndex((el) => el.dataIndex == "nameShow");

      if (index === -1) {
        this.columns.splice(0, 0, {
          title: str,
          dataIndex: "nameShow",
          key: "nameShow",
          width: 200,
        });
      } else {
        this.columns.splice(index, 1, {
          title: str,
          dataIndex: "nameShow",
          key: "nameShow",
          width: 200,
        });
      }
      let arr = [];
      this.cardList.forEach((el) => {
        arr.push({
          title: el.name,
          dataIndex: "card" + el.id,
          key: "card" + el.id,
          scopedSlots: { customRender: "card" + el.id },
          width: 150,
        });
      });

      return [...this.columns, ...arr];
    },
  },
  created() {
    this.getUnits();
    this.getCategories();
    this.getFreightTemplates();
    this.getCards();
    // console.log(this.$route.query.id, 'route---id')

    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  watch: {
    specArray: {
      handler: function () {
        this.getDataSource();
      },
      deep: true,
    },
  },
  methods: {
    handleEnlargeImage(enlargeImage) {
        this.enlargeImage = enlargeImage;
        this.imageVisible = true;
      },
    deleteVideo () {
              this.$set(this.form, "video", '');
        this.$set(this.form, "cover_url", '');
        this.$set(this.form, "file", null);
    },
    onChange() {
      console.log(this.checkedAll, "this.checkedAll");
      if (this.checkedAll) {
        this.cardChoose = this.cardList;
        let list = this.plainOptions.map((el) => el.value);
        this.$set(this.info, "setList", list);
      } else {
        this.$set(this.info, "setList", []);
        this.cardChoose = [];
      }
      console.log(this.info, "this.info");
    },
    delAlImage() {
      this.fileList = [];
    },
    openCover(url) {
      window.open(url);
    },
    getDetail() {
      this.$axios.get(`/goods/${this.$route.query.id}/`).then((res) => {
        console.log(res, "route----");
        this.form = res;
        this.form.cover_url = res.video_cover_url || "";
        if (this.$route.query.copy) {
          delete this.form.id;
          this.form.number = "";
        }
        this.specArray = JSON.parse(res.spec_json);
        this.fileList = res.picture_items || [];

        this.detailInfo = res;

        // this.$nextTick(() => {
        //   this.dataSource = res.spec_items || [];
        // });
        setTimeout(() => {
          this.dataSource = res.spec_items || [];
          console.log("-----1");
        }, 800);

        // console.log(this.dataSource, 'detaildata', res.spec_items)
      });
    },
    openSet(obj) {
      this.setVisible = true;
    },
    setOk(list) {
      this.setVisible = false;
      let file = list[0];
      if (file) {
        this.editForm.picture = file.id;
        this.editForm.image = file.image;
      }
    },
    plainOptionsShow(key) {
      let index = this.plainOptions.findIndex((el) => el.value === key);
      if (index > -1) {
        return this.plainOptions[index].label;
      }
      return "";
    },
    handleOkSpec() {
      console.log(this.editForm, "editForm");
      if (this.currentEditSpec.specList) {
        this.currentEditSpec.specList.push({
          ...this.editForm,
        });
      } else {
        this.$set(this.currentEditSpec, "specList", [
          {
            ...this.editForm,
          },
        ]);
      }
      console.log(this.specArray, "spec", this.currentEditSpec);
      let index = this.specArray.findIndex(
        (el) => el.spec === this.currentEditSpec.spec
      );
      if (index > -1) {
        this.specArray.splice(index, 1, this.currentEditSpec);
      }
      this.specValVisible = false;
    },
    addSpec(item) {
      this.specValVisible = true;
      this.currentEditSpec = item;
      this.editForm = {};
      // return;
      // if (item.specList) {
      //   item.specList.push({
      //     specVal: "",
      //   });
      // } else {
      //   this.$set(item, "specList", [{ specVal: "" }]);
      // }
    },
    deleteSpec(index, list) {
      console.log(index, list, "====");
      list.splice(index, 1);
    },
    batchSetting(str) {
      console.log(this.dataSource, "datasource");
      if (this.dataSource && this.dataSource.length > 1) {
        let obj = this.dataSource[0];
        if (obj[str] || obj[str] === 0) {
          this.dataSource.forEach((el) => {
            this.$set(el, str, obj[str]);
          });
          this.changePrice();
        }
      }
    },
    getDataSource() {
      let arr = this.specArray.reduce((prev, cur) => {
        let emptyList = [];
        if (prev.length === 0) {
          return (
            (cur.specList &&
              cur.specList.map((el) => {
                return {
                  ...el,
                  name: `${cur.spec}: ${el.name}`,
                  nameShow: `${el.name}`,
                };
              })) ||
            []
          );
        }

        if (!cur.specList || cur.specList.length === 0) {
          return prev;
        }

        let arrMap =
          cur.specList &&
          cur.specList.map((el) => {
            return {
              ...el,
              name: `${cur.spec}: ${el.name}`,
            };
          });

        prev.forEach((el) => {
          arrMap.forEach((val) => {
            emptyList.push({
              ...el,
              ...val,
              name: `${el.name},${val.name}`,
              nameShow: `${el.name.split(": ")[1]} ${val.name.split(": ")[1]}`,
            });
          });
        });
        return emptyList;
      }, []);

      this.dataSource.forEach((el) => {
        arr.forEach((m) => {
          if (el.name === m.name) {
            m = Object.assign(m, el);
          }
        });
      });

      this.dataSource = arr;

      // if (this.detailInfo && Object.keys(this.detailInfo).length > 0 && this.detailInfo.spec_items && this.detailInfo.spec_items.length > 0 ) {
      //   this.dataSource = this.detailInfo.spec_items || []
      // }

      console.log(this.dataSource, "watch");
    },
    delImg(obj) {
      let index = this.fileList.findIndex((el) => el.id === obj.id);
      this.fileList.splice(index, 1);
    },
    openPhoto() {
      this.photoVisible = true;
    },
    openSpec(obj) {
      this.specVisible = true;
      this.currentSpec = obj;
    },
    specOk(list) {
      this.specVisible = false;
      let file = list[0];
      if (file) {
        this.currentSpec.picture = file.id;
        this.currentSpec.image = file.image;
      }
      // this.currentSpec.image
      // this.fileList = [...this.fileList, ...list];
    },
    specCancel() {
      this.specVisible = false;
    },
    photoOk(list) {
      this.photoVisible = false;
      this.fileList = [...this.fileList, ...list];
    },
    photoCancel() {
      this.photoVisible = false;
    },
    // uploadChangeRecord(record, event) {
    //   let { file } = event;
    //   if (file.status == "done") {
    //     record.picture = file.response.id;
    //     record.image = file.response.image;
    //     this.$set(record, "image", file.response.image);
    //   }
    //   console.log(this.dataSource);
    // },
    changePrice() {
      let _this = this;
      _this.cardDataSource.forEach((el) => {
        let obj = _this.dataSource.find((m) => m.nameShow == el.nameShow);
        if (obj) {
          _this.$set(el, "show_price", obj.price);
        }
      });
    },

    uploadChange({ file, fileList }) {
      console.log(fileList);

      console.log(file);

      this.spinning = true;

      if (file.status === "done") {
        this.$set(this.form, "video", file.response.id);
        this.$set(this.form, "cover_url", file.response.cover_url);
        this.$set(this.form, "file", file.response.file);
        this.spinning = false;
      }
      if (file.status === "error") {
        this.spinning = false;
      }
      // this.fileList = fileList;
    },
    addName() {
      this.visible = true;
      this.info = {
        name: "",
        setList: [],
      };
      this.cardChoose = [];
      this.checkedAll = false;
    },

    handleOk() {
      if (this.info.name) {
        this.specArray.push({
          spec: this.info.name,
          setList: this.info.setList,
          cardList: this.cardChoose,
        });
      }
      this.visible = false;
    },
    getUnits() {
      this.$axios.get("/units/").then((res) => {
        console.log(res);
        this.unitsList = res.results || [];
      });
    },
    getCategories() {
      this.$axios
        .get("/categories/", { params: { page_size: 999999 } })
        .then((res) => {
          console.log(res);
          this.categoriesList = res.results || [];
        });
    },
    getFreightTemplates() {
      this.$axios
        .get("/freight_templates/", { params: { page_size: 9999 } })
        .then((res) => {
          this.freightList = res.results || [];
        });
    },
    getCards() {
      this.$axios
        .get("/cards/", { params: { page_size: 9999 } })
        .then((res) => {
          this.cardList = res.results || [];
          console.log(this.cardList, "111");
        });
    },
    serialArray(arr) {
      let lengthArr = [];
      let productArr = [];
      let result = [];
      let length = 1;
      for (let i = 0; i < arr.length; i++) {
        let len = arr[i].length;
        lengthArr.push(len);
        let product = i === 0 ? 1 : arr[i - 1].length * productArr[i - 1];
        productArr.push(product);
        length *= len;
      }
      for (let i = 0; i < length; i++) {
        let resultItem = [];
        for (let j = 0; j < arr.length; j++) {
          resultItem.push(arr[j][Math.floor(i / productArr[j]) % lengthArr[j]]);
        }
        result.push(resultItem.join(","));
      }
      return result;
    },
    beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 512;
      console.log(isLt500Kb, file.size / 1024);
      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(512KB)，请修改后重新上传");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.video__box {
  display: flex;
  flex-flow: column nowrap;
}

.custom__checkbox_group {
  display: block;
  /deep/ .ant-checkbox-group-item {
    display: block;
  }
  /deep/ .ant-checkbox-wrapper {
    display: block;
    margin-left: 0;
  }
}
.product {
  &__title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 4px;
    background: #dddddd;
    font-weight: 700;
    opacity: 0.8;
  }
}
.box {
  border: 1px solid #ddd;
  min-height: 300px;
  position: relative;
  ul {
    padding: 4px;
  }
  .box__btn {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }
}
.input__box {
  position: relative;
  display: inline-block;
}
.input__box_close {
  position: absolute;
  top: 0;
  right: -4px;
  cursor: pointer;
  background: #fff;
  // transform: translateX(-50%) translateY(-50%);
}
.photoBtn {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 15px;
  cursor: pointer;
}
.img__show {
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  .delBtn {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 20px;
    line-height: 20px;
    color: red;
    text-align: center;
    /* padding: 0 20px; */
    font-size: 10px;
    background: rgba(0, 0, 0, 0.3);
  }
  .enlargeBtn {
    position: absolute;
    left: 50%;
    top: 0;
    width: 50%;
    height: 20px;
    line-height: 20px;
    color: red;
    text-align: center;
    /* padding: 0 20px; */
    font-size: 10px;
    background: rgba(0, 0, 0, 0.3);
  }
}
.batch__setting {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  > li {
    margin-right: 20px;
    cursor: pointer;
  }
}
</style>